#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    justify-content: center;
    display: flex;
}

#progressbar .active {
    color: #0047AB
}
.noedit{
    cursor: default;
}
.yesedit{
    cursor: pointer;
}
#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400
}

#progressbar #account:before {
    content: url('../icon/checkk.png');
    display: block;
    margin: auto;
}

#progressbar #personal:before {
    font-family: FontAwesome;
    content: "\f007"
}

#progressbar #payment:before {
    font-family: FontAwesome;
    content: "\f030"
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: "\f00c"
}
#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 10px;
    z-index: 2;
    position: relative;
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: 1
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #0047AB
}

.progress {
    height: 20px
}

.progress-bar {
    background-color: #0047AB
}

.fit-image {
    width: 100%;
    object-fit: cover
}