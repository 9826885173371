.nav-control {
  display: none;
}

.font-capitalize {
  text-transform: capitalize;
}

.deznav {
  background-color: #f1f1f1 !important;
}

.deznav-scroll {
  background: #fff !important;
  margin: 30px 20px !important;
  border-radius: 30px !important;
}

.button-border {
  border: 1px solid #0047AB;
  padding: 7px 14px !important;
  border-radius: 12px !important;
}

.button-join-white .bg-style {
  background: #fff !important;
  color: #0047AB !important;
}

.button-join .bg-style {
  background: #0047AB;
  color: #fff;
  padding: 9px 15px !important;
  border-radius: 1rem;
  margin: 2px !important;
}

.bg-dark {
  background: #000 !important;
}

.nav-header {
  background-color: #F1F1F1 !important;
}

ul#menu span {
  color: #737373 !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a {
  padding: 11px 12px 10px 10px !important;
}

.metismenu img {
  margin-right: 15px;
}

.breadcrumb-item {
  text-transform: capitalize;
}

.event-list-box {
  background: rgba(0, 71, 171, 0.15);
  padding: 16px 30px 10px 30px;
  border-radius: 15px;
  height: 85px;
  z-index: 1;
}

.event-list-box p {
  margin: 0px !important;
}

.event-ticket-box {
  background: rgba(0, 71, 171, 1);
  display: inline-block;
  padding: 14px 22px 0px 22px;
  border-radius: 0px 0px 10px 10px;
  margin-top: 0px;
  margin-left: 50px;
}

.gradient-blue {
  background: linear-gradient(180deg, rgba(0, 71, 171, 0.55) 0%, rgba(100, 149, 237, 0) 100%);
}

.gradient-grey {
  background: linear-gradient(180deg, rgba(181, 181, 181, 0.55) 0%, rgba(153, 153, 153, 0) 100%);
}

.event_category_box {
  padding: 60px 20px;
  width: 370px;
  border-radius: 15px;
  position: relative;
}

.icon_section {
  position: absolute;
  left: 0;
  top: -65px;
}

.custome-text-area {
  border: 1px solid rgba(184, 185, 185, 1);
  height: 300px;
  width: inherit;
  padding: 5px 9px;
  border-radius: 15px;
}

.deznav .metismenu>li a:hover {
  color: #0047AB !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_3"] .deznav .metismenu a:hover,
[data-sibebarbg="color_3"] .deznav .metismenu a:focus,
[data-sibebarbg="color_3"] .deznav .metismenu a.mm-active {
  color: #000 !important;
}


.tab-button-box {
  background: #fff;
  border: 1px solid rgba(184, 185, 185, 1);
  padding: 0px;
  border-radius: 5px;
  overflow: hidden;
  width: fit-content;
}

.Display-date-time-tic p {
  font-size: 12px;
}

.date-border-redius-input {
  height: auto;
}

.tab-button-box .tab-button-active {
  background: rgba(0, 71, 171, 1);
  color: #fff !important;
}

.tab-button-box .tab-button-grey-active {
  background: rgba(241, 241, 241, 1);
  color: #000 !important;
}

.tab-button-box span {
  font-size: 14px;
  color: #000 !important;
  display: inline-block;
  text-align: center;
  padding: 5px 15px;
  margin: 6px;
  border-radius: 5px;
  cursor: pointer;
}

span.input-group-text {
  background: #fff !important;
  border: 1px solid rgba(184, 185, 185, 1) !important;
}

[data-theme-version="light"] .input-warning-o .input-group-text,
[data-theme-version="dark"] .input-warning-o .input-group-text {
  background-color: transparent;
  border-color: rgba(184, 185, 185, 1);
  color: rgba(184, 185, 185, 1);
}

[data-theme-version="light"] .input-warning-o .form-control,
[data-theme-version="dark"] .input-warning-o .form-control {
  border-color: rgba(184, 185, 185, 1);
}

.checkout-style-element {
  position: absolute;
  bottom: 0px;
}

.checkout-style-bottom {
  position: relative;
}

/* .form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  width: 190px;
  height: 28px;
  margin-top: 22px;
  background: rgba(0, 71, 171, 0.25);
} */
.cursor-pointer {
  cursor: pointer;
  /* padding: 0px 0px 13px 0px; */
}

.content-body {
  min-height: 100vh;
}

.btn-check:focus+.button-join,
.button-join:focus {
  outline: 0;
  box-shadow: none !important;
}

.table-responsive {
  padding-bottom: 80px;
}

.page-theme-btn:hover {
  color: #fff;
}

.page-theme-btn {
  background: #0047ab;
  color: #fff;
  padding: 10px 20px;
  border-radius: 23px;
}

.position-right {
  position: absolute;
  right: 14px;
  top: 6px;
}

.page-titles {
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
}

.linear-background {
  border-radius: 20px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 338px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.l-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.input-group.mb-3.input-warning-o {
  border: 1px solid #eee;
  border-radius: 6px;
}

.date-border-redius {
  border-radius: 0px 10px 10px 0px !important;
}

.tag-preview-option ul li .delete-button {
  background: #fff;
  border: none;
  padding: 7px 7px;
  line-height: 10px;
  border-radius: 50%;
  margin-left: 13px;
  font-weight: 300;
}

.tag-preview-option ul li {
  display: inline-block;
  background: #0047ab;
  color: #fff;
  padding: 9px 12px;
  margin-right: 16px;
  border-radius: 24px;
  margin-top: 16px;
}

p.ticket-price-p {
  margin: 0;
  font-size: 21px;
  font-weight: 600;
  color: #0047ab;
}

p.ticket-sold-count {
  font-weight: 500;
  margin: 0;
}

span.ticket-date {
  color: #000;
  font-size: 15px;
}

span.devide-dot {
  padding: 0px 12px;
}

p.price-title {
  font-size: 28px;
  font-weight: 600;
  color: #0047ab;
}

.price-list-box .row {
  display: flex;
  justify-content: left;
  align-items: center;
}

.price-list-box .row {
  border-bottom: 1px solid #e5e5e5;
}

p.no_ticket_added {
  color: #0047ab;
  font-size: 27px;
  border-radius: 12px;
  padding: 18px 0px;
}

.no-data-found {
  border: 1px solid #00000024;
  border-radius: 13px;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #0047ab !important;
  border-color: #0047ab !important;
}

.flatpickr-weekdays {
  background: #0047ab !important;
}

span.flatpickr-weekday {
  background: #0047ab !important;
  color: #fff !important;
}

.flatpickr-months .flatpickr-month {
  background: #0047ab !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: #0047ab !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #0047ab !important;
}

.button-join {
  cursor: pointer;
  display: contents;
}

.whitestar-icon img {
  height: auto;
  width: 23px;
}

.button-join span {
  display: inline-block;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.button-join:hover .btn-a {
  transform: scale(0);
  opacity: 0;
}

.button-join:hover .btn-b {
  /* transform: translateX(-39%); */
  opacity: 1;
}

.button-join:hover .btn-c {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.btn-c {
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

.overflow-y-scroll {
  overflow-y: auto !important;
}

.ticket-box {
  background: #fff;
  /* padding: 30px 20px; */
  border-radius: 10px;
  margin: 10px 00px;
}

.ticket-qr {
  padding-bottom: 40px;
}

.ticket-view-title {
  margin-bottom: 0px;
  font-size: 14px;
}

.ticket-view-data {
  font-weight: 600;
  color: #000;
  font-size: 17px;
}

.ticket-list-row {
  overflow-y: auto;
  height: 500px;
}

.modal-dialog.ticket-view-page .modal-content {
  background: #eee;
}

@media only screen and (max-width: 900px) {
  .admin-panel-logo {
    height: auto;
    width: 60px !important;
  }

  .nav-control {
    display: inline;
  }

  [data-sidebar-style="overlay"] .deznav .metismenu>li>a {
    font-size: 16px;
    padding: 11px 0px 10px 0px;
    color: #7f7184;
    border-radius: 2.75rem;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sibebarbg="color_3"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
  [data-sibebarbg="color_3"] .menu-toggle .deznav .metismenu li>ul {
    background: #fff !important;
  }
}

.cursor-pointre {
  cursor: pointer;
}

.admin-panel-logo {
  height: auto;
  width: 150px;
}

.header-logo-in-sidebar {
  height: auto;
  width: 150px;
}

.menu-icon svg {
  font-size: 85px;
}

.menu-icon [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_3"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_3"] .deznav .metismenu>li>a svg {
  color: #0047ab !important
}

.menu-icon {
  margin-right: 12px;
}

.footer-logo {
  height: auto;
  width: 150px;
}

.hobby-box {
  border: 1px solid #0047ab;
  margin: 10px 3px;
  padding: 2px 10px;
  border-radius: 15px;
  color: #0046ab;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  background: #fff;
}

img.event-card-flag {
  height: auto;
  width: 50px;
  position: absolute;
  bottom: 9px;
  right: 10px;
}

.organizer-name-sec {
  font-size: 11px !important;
}

.organizer-name-sec .shdsjds {
  height: auto;
  width: 20px;
}

.ticket-price-area.ticket-price-area-bg {
  padding-bottom: 1px !important;
}

.hobby-active {
  background: #0047ab;
  color: #fff;
  border: 1px solid #0047ab;
}

.no-data-box p {
  margin-bottom: 0px;
  padding: 20px 0px;
}

.no-data-box {
  width: 100%;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.date-style-picker {
  position: absolute;
  width: 100%;
  opacity: 0;
}

.deznav .metismenu {
  max-height: 80vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: transparent transparent;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 11 */
}

.deznav .metismenu::-webkit-scrollbar {
  width: 6px;
  /* Adjust as needed */
}

.deznav .metismenu::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Adjust as needed */
}

.nav-header {
  top: 20px !important;
}

.header {
  height: 100px !important;
  position: relative;
}

.whiteblueicontitle {
  color: #0047ab;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 21px;
  margin-right: 10px;
}

.whiteblueicon img {
  height: auto;
  width: 22px;
  top: -2px;
  position: relative;
}

.whiteblueicon_box {
  border: 1px solid transparent;
  padding: 4px 16px;
}

.user_type_btn {
  background: linear-gradient(white, #d5e7ff) padding-box, linear-gradient(to top, #0047AB, #ffffff) border-box;
  border-radius: 15px;
  border: 2px solid transparent;
  padding: 9px 19px 3px 19px;
  color: #0047ab;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.user_type_btn_position {
  position: absolute;
  right: 15%;
  top: -10px;
}

.new_user_menu_header {
  background: #fff !important;
  height: 53px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0px 9px !important;
  border-radius: 25px;
}

.header-right .header-profile img {
  width: 40px;
  height: 40px;
  border-radius: 0.5rem;
  margin-right: 10px;
  border-radius: 100%;
}

span.user_name {
  margin-right: 11px;
  font-weight: 600;
  color: #000;
  font-size: 13px;
}

span.user_drop_icon {
  font-size: 21px;
}

.grey-bg {
  background: #EAEAEA;
}

[data-theme-version="light"] .grey-bg .input-warning-o .input-group-text,
[data-theme-version="dark"] .grey-bg .input-warning-o .input-group-text {
  border-color: transparent !important;
}

.grey-bg .form-control {
  border: 0.0625rem solid transparent !important;
}

.search-box-icon-1 {
  color: #000 !important;
  font-size: 14px;
}

.input-warning-o .form-control {
  padding: 0.375rem 5px;
}

.theme-btn-warning {
  background: #FFA619;
  color: #fff;
  padding: 9px 0px 9px 0px;
  border-radius: 8px;
  border: none;
  font-size: 15px;
}

/* .theme-btn {
  background: #0047AB;
  color: #fff;
  padding: 3px 0px 7px 0px;
  border-radius: 8px;
  border: none;
  font-size: 15px;
} */
.theme-btn {
  background: #0047AB;
  color: #fff;
  padding: 0px;
  border-radius: 8px;
  border: none;
  font-size: 15px;
  line-height: 0px;
  height: 40px;
  padding: 0px 10px;
}

.theme-btn-light {
  background: #fff;
  color: #4B5563;
  padding: 3px 0px 7px 0px;
  border-radius: 8px;
  border: none;
  font-size: 15px;
}

.theme-btn-icon {
  font-size: 20px;
}

.event_list_box {
  background: #fff;
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid #eee;
}

img.list-thum-img {
  height: 100%;
  width: 100%;
}

.list-event-name {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-right: 10px;
}

.list-event-edit-btn img {
  height: auto;
  width: 30px;
}

.list-event-location {
  position: relative;
  left: -34px;
  margin-bottom: 0px;
}

.list-Total-Ticket {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.list-Ticket-amount {
  color: #0047AB;
  font-size: 18px;
  font-weight: 600;
}

.list-Ticket-sold {
  font-weight: 600;
  color: #0047AB;
  margin-left: 10px;
}

.list-ticket-count {
  position: relative;
  left: 20px;
  top: 0;
}

.list-data {
  padding: 5px 0px;
}

.list-event-category-img {
  background: rgba(0, 71, 171, 0.5);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
}

.list-Ticket-1 {
  font-size: 16px;
  font-weight: 600;
  color: #0047ab;
  margin-right: 40px;
  padding-top: 00px;
}

.list-Ticket-mng-1 {
  padding: 5px 15px;
}

.list-rais-ticket-btn {
  background: #D00000;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  border: 0px;
  padding: 5px 24px 30px 24px;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  right: 16px;
  top: -29px;
  z-index: -1;
}
.list-active-ticket-btn-red{
  background: #be0000 !important;
}
.list-active-ticket-btn {
  background: #0047ab;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  border: 0px;
  padding: 28px 30px 6px 30px;
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  right: 16px;
  bottom: -30px;
  z-index: -1;
}

.event_list_box_main {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
  margin-top: 20px !important;
}

.input-group-text {
  min-width: 25px;

}

.desc_data img {
  height: auto;
  width: 15px;
  margin-left: 4px;
}

.desc_data .event-duration {
  color: #000;
  font-weight: 600;
  font-size: 13px;
}

.list-event-desc {
  font-size: 15px;
}

.in-ticket-list-1 {
  margin-bottom: 15px;
}

.in-ticket-list-1 .event_list_box {
  padding: 10px 30px;
  border-radius: 14px;
}

.in-ticket-list-1 .ticket-list-name {
  color: #0047ab;
  font-weight: 600;
  font-size: 14px;
  margin-right: 0px;
  margin-bottom: 10px;
  display: inline-block;
}

.in-ticket-list-1 .ticket-list-price_title {
  font-weight: 600;
  font-size: 12px;
}

.in-ticket-list-1 .ticket-list-price_value {
  font-size: 12px;
  font-weight: 600;
  color: #0047ab;
}

.ticket-sts-box h2 {
  font-weight: 600;
  color: #000;
}

.my-follower-account-box {

  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(335deg, #fff, #0047ab) border-box;
  border: 1px solid transparent !important;
  color: #003b8f !important;
  border-radius: 10px;
  padding: 10px 0px;
}

.my-follower-account-box p.org-name {
  font-weight: 600;
  color: #0047ab;
  font-size: 14px;
}

.my-follower-account-box p.org-event-count {
  font-size: 16px;
  font-weight: 500;
}

button.Unfollow-btn-1 {
  background: #0047ab;
  border: 2px solid #0047ab;
  border-radius: 7px;
  padding: 3px 15px;
  font-weight: 600;
  color: #ffffff;
}

.reward-ui-box {
  background: linear-gradient(white, #fff) padding-box, linear-gradient(to bottom, #0047AB, #fff) border-box;
  padding-top: 40px;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid transparent;
}

.contact-us-form {
  background: linear-gradient(white, #fff) padding-box, linear-gradient(to bottom, #0047AB, #fff) border-box;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid transparent;
}

p.rewarx-box-c-title {
  color: #000;
  font-weight: 400;
  margin-bottom: 10px;
}

p.rewarx-box-c-sts {
  font-weight: 600;
  color: #000;
  font-size: 22px;
}

span.colplete-yext-1 {
  font-weight: 500;
  font-size: 15px;
}

p.reward-point-text {
  font-weight: 600;
  color: #000;
  font-size: 21px;
  margin-bottom: 2px;
}

p.reward-point-count {
  color: #0047ab;
  font-weight: 600;
  font-size: 38px;
}

p.Booking-progress-towards span {
  font-weight: 600;
}

p.Booking-progress-towards {
  color: #000;
  font-size: 16px;
}

span.rewrd-user-name1 {
  color: #000;
  font-weight: 500;
  font-size: 21px;
  margin-left: 20px;
}

.ticket-form .css-13cymwt-control,
.ticket-form .css-t3ipsp-control {
  min-height: 40px !important;
}

.react-select-h .css-13cymwt-control,
.react-select-h .css-t3ipsp-control {
  min-height: 40px !important;
}

.react-select-h .css-1u9des2-indicatorSeparator {
  width: 0px;
}

[data-theme-version="light"] .grey-border.input-warning-o .input-group-text,
[data-theme-version="dark"] .grey-border.input-warning-o .input-group-text {
  border-color: #eee !important;
}

.grey-border .form-control {
  border-left: none;
  border-top: 0.0625rem solid #eee !important;
  border-right: 0.0625rem solid #eee !important;
  border-bottom: 0.0625rem solid #eee !important;
}


.support-tickets-list-1 {
  border: 1px solid #eee;
  padding: 15px 15px 0px 15px;
  border-radius: 5px;
}

span.ticket-sts-icon {
  margin-right: 10px;
  font-size: 20px;
}

span.ticket-head-tt1 {
  font-weight: 600;
  color: #000;
  font-size: 18px;
}

p.ticket-type-12 {
  margin: 0;
  font-weight: 500;
  color: #000;
  font-size: 15px;
}

p.ticket-message7 {
  font-size: 14px;
}

.xyz-ticket-desc-box {
  padding: 6px 0px;
  border-bottom: 1px solid #eee;
}

.ticket-box-time1 {
  padding-top: 13px;
}

a.Open-Ticket-link {
  color: #2f68b8;
  border-bottom: 1px solid #2f68b8;
  cursor: pointer;
}

.cpointer {
  cursor: pointer;
}

.no-result-div p {
  font-weight: 600;
  color: #000;
  font-size: 25px;
  text-transform: uppercase;
}

.no-result-div {
  border: 1px solid #d7d7d7;
  border-radius: 7px;
  padding: 20px 0px;
  box-shadow: -1px 2px 5px 0px rgba(219, 219, 219, 0.71);
  -webkit-box-shadow: -1px 2px 5px 0px rgba(219, 219, 219, 0.71);
  -moz-box-shadow: -1px 2px 5px 0px rgba(219, 219, 219, 0.71);
}

span.bage-danger-css {
  background: #FEF1F3;
  color: #FF0000;
  padding: 6px 15px;
  margin-left: 10px;
  border-radius: 5px;
}

span.bage-light-css {
  background: #f5f5f5;
  color: #999999;
  padding: 6px 15px;
  margin-left: 10px;
  border-radius: 5px;
}

.reply-message-cs {
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.white-table .table thead tr {
  background: #ffffff !important;
  color: #898989 !important;
  border-bottom: 2px solid #e9e9e9 !important;

}

.white-table .table> :not(caption)>*>* {
  background-color: white !important;
  text-align: center;
}

.badge-theme {
  padding: 3px 7px;
  border-radius: 5px;
  font-size: 14px;
}

.badge-theme-warning {
  background: #FFF6E9;
  color: #B5850B;
}

.badge-theme-success {
  background: #EDFFEA;
  color: #165E3D;
}

.badge-theme-danger {
  background: #FFEAEA;
  color: #B83131;
}

span.order-view-btn {
  margin-left: 10px;
  cursor: pointer;
}

.tickets-data-text h5 {
  font-weight: 600;
  font-size: 16px;
}

.tickets-data-text p {
  font-weight: 400;
  color: #000;
  font-size: 13px;
}

.navigation-box {
  position: relative;
}

.navigation-box::before {
  content: ' ';
  background: #cdcdcd;
  height: 1px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.under_line_css:hover {
  color: #0047ab;
  border-bottom: 2px solid #0047ab;
}

.under_line_css {
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.under_line_css_active {
  color: #0047ab;
  font-weight: 600;
  border-bottom: 2px solid #0047ab;
}

img.TranferImg-css {
  height: auto;
  width: 500px;
}

.br-n {
  border-right: none !important;
}

.blr-n {
  border-left: none !important;
  border-right: none !important;
}

.bl-n {
  border-left-color: transparent !important;
}

button.signup-page-button {
  background: #003b8f;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 400;
  padding: 8px 56px;
}

.form-area {
  padding: 50px 50px;
}



.page-titles a {
  color: #fff !important;
}

.bb2s {
  border-bottom: 2px solid #eee;
  border: 2px solid #eee;
}

.blr2s {
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
}

.text-primary-theme {
  color: #003b8f !important;
}

.reward_star {
  position: absolute;
  font-size: 36px;
  top: -12px;
  width: 91px;
  text-align: center;
}

.reward_star_grew {

  font-size: 36px;
  top: -12px;
}

.reward_star_gold {

  font-size: 36px;
  top: -12px;
}

.reward_star_premeum img {
  margin-left: 10px;
}

.reward_star_premeum {

  font-size: 36px;
  top: -12px;
  width: 150px;
}

.reward_star_grew p.reward_star_text {
  font-size: 16px;
  font-weight: 600;
}

.reward_star_gold p.reward_star_text {
  font-size: 16px;
  font-weight: 600;
  color: #FFA619;
}

.reward_star_premeum p.reward_star_text {
  font-size: 16px;
  font-weight: 600;
  color: #0047AB;
}

.view-event-btn {
  background: #0047ab;
  color: #fff !important;
  padding: 4px 8px;
  border-radius: 5px;
  float: inline-end;
}

.cart-count {
  position: absolute;
  top: -8px;
  /* Adjust the positioning as needed */
  right: -8px;
  /* Adjust the positioning as needed */
  background-color: #000;
  /* Blue background color */
  color: #fff;
  /* Text color */
  border-radius: 50%;
  /* Make it circular */
  width: 20px;
  /* Adjust the size as needed */
  height: 20px;
  /* Adjust the size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  /* Adjust the font size as needed */
  font-weight: bold;
}

/* .nav-control {
  display: none;
} */
.home_logo {
  height: auto;
  width: 180px;
}

.deznav .deznav-scroll {
  height: 612px !important;
}

.coupon-page-ui .col-md-4 {
  background: #0047ab;
  color: #fff;
}

p.coupon-amount {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 0;
}

.shadow {
  -webkit-box-shadow: 1px 2px 5px 0px rgba(191, 191, 191, 1);
  -moz-box-shadow: 1px 2px 5px 0px rgba(191, 191, 191, 1);
  box-shadow: 1px 2px 5px 0px rgba(191, 191, 191, 1);
}

.tickret-show-box {
  position: relative;
  height: 200px;
  padding: 14px 55px;
}

img.ticketimg-bg:hover {
  opacity: 0.6;
}

img.ticketimg-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 77%;
  width: 100%;
  opacity: 0.3;
}

button.redem-btn {
  border: 1px solid #000;
  background: none;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  padding: 8px 40px;
  position: relative;
}

button.redem-btn:hover img.ticketimg-bg:hover {
  opacity: 0.6;
}

button.redem-btn:hover {
  border: 1px solid #0047ab;
}

span.token-no-span {
  background: #eee;
  padding: 5px 9px;
  color: #838383;
  font-weight: 600;
  margin-right: 10px;
  font-size: 13px;
}

button.redem-copy-btn {
  background: #0047ab;
  border: none;
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  position: relative;
  font-size: 15px;
}

img.flag-icon {
  position: absolute;
  left: -12px;
  top: -22px;
  height: auto;
  width: 66px;
}

.xyss {
  background: linear-gradient(#d5e7ff, #fff) padding-box, linear-gradient(335deg, #0047ab, #fff) border-box;
  border: 1px solid transparent !important;
  color: #003b8f !important;
  border-radius: 10px;
}

p.sssas {
  font-weight: 600;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0px;
}

.event-cart-location {
  font-size: 13px;
}


.event-cart-display-price {
  font-size: 16px;
}

.pc-header-location {
  position: relative;
  z-index: 9;
  top: 6px;
  background: #fff;
  padding: 10px 0px 0px 0px;
  left: -1px;
  border-radius: 0px 0px 10px 10px;
  width: 181px;
}

.top-login-sign-btn:hover span.top-login-sign-btn-icon {
  color: #65a1ff;
}

.top-login-sign-btn {
  -webkit-box-shadow: 2px 2px 8px -4px rgba(66, 66, 66, 1);
  -moz-box-shadow: 2px 2px 8px -4px rgba(66, 66, 66, 1);
  box-shadow: 2px 2px 8px -4px rgba(66, 66, 66, 1);
  border-radius: 36px;
  padding: 0px 12px 0px 19px;

  cursor: pointer;
}

span.top-login-sign-btn-icon {
  font-size: 29px;
  line-height: 0px;
  color: #cde1ff;
  transition: color 0.5s ease;
}

.top-login-sign-btn img {
  height: 40px;
  width: 40px;
  margin: 4px 4px 4px 9px;
}

.animation-home-banner {
  margin-left: 17px;
  font-size: 30px;
  padding: 0px 15px;
  border: 2px dashed #fff;
  width: 547px;
  border-radius: 8px;
  height: 50px;
}

.banner-h-home-pg {
  font-size: 50px;
}

.theme-bg {
  background: #0047AB !important;
}

.xxx-conta {
  position: relative;
  width: 200px;
  height: 40px, ;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 10px
}

.xxx-event-page-top-loader {
  margin: 78px 23px 0px 23px;
}

img.cart-btn-img {
  height: auto;
  width: 40px;
}

.copy-n {
  user-select: none;
}

.tag-active {
  background: #0047ab;
  color: #fff !important;
}

.BeyondTickets-sec {
  margin: 95px 0px 65px 0px;
}

.eventpage-cat-name {
  padding-left: 5rem;
  padding-top: 4rem;
}

.eventpage-cat-name span {
  border: 2px dashed white;
  padding: 6px 10px;
  border-radius: 8px;
}

.admin-login-img {
  height: auto;
  width: 340px;
}

.OURPARTNER-padding {
  padding: 0px 25px 0px 0px;
}

.social-icon {
  background: none !important;
  cursor: pointer;
}

.login-pg-btn-cs {
  background: #6495ED !important;
}

.login-pg-btn-cs .right {
  background: #0047AB !important;
}

span.add_to_cart_count {
  font-size: 25px;
  padding: 0px 11px;
  width: 40px;
  user-select: none;
}

.add_to_cart_btn {
  background: #0e51b0;
  cursor: pointer;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  font-size: 21px;
  padding: 3px 11px 0px 12px;
  border-radius: 8px;
  margin: 0px 1px;
}

span.event-pg-icon-img img {
  height: auto;
  width: 28px;
  margin-right: 10px;
  margin-left: 20px;
}

span.event-pg-icon-text {
  font-size: 15px;
}

.event-pg-icon {
  font-size: 23px;
  margin-right: 10px;
  line-height: 3px;
}

.row.evnt-page-save-shear-box {
  margin-right: 80px;
  position: relative;
  top: -52px;
}

.event-hero {
  border-radius: 20px;
  position: relative;
  max-width: 1080px;
  max-width: var(--container-max-width);
  margin: 0 auto;
  text-align: center;
  max-height: 50vw;
  overflow: hidden;
  font-size: 0;
  background-color: #fff;
}

.event-hero__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  max-width: 1080px;
  max-width: var(--container-max-width);
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-filter: blur(50px) brightness(.9);
  filter: blur(50px) brightness(.9);
  z-index: 0;
}

.css-1vu2yqv {
  position: static;
}

.css-1tue3c7 {
  overflow: hidden;
  max-width: 940px;
  margin: auto;
  border-radius: 10px;
}

.css-6dsmqv img {
  max-width: 940px;
  width: 100%;
  height: auto;
}

.event-hero img {
  max-width: 940px;
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}

.event-hero {
  max-height: 470px;
}

.banner-h-home-pg {
  padding-left: 5rem;
}

.events-page-search {
  border: 1px solid #eee !important;
  border-radius: 8px !important;
  padding-right: 0px !important;
}

.events-page-search button.dfssfdsfdsf {
  margin-top: 4px;
  border-radius: 0px 6px 6px 0px;
  height: 80% !important;
  padding-bottom: 3px !important;
}

.events-page-filter-box {
  background: linear-gradient(white, #fff) padding-box, linear-gradient(to top, #0047AB, #eee) border-box;
  padding-top: 40px;
  border-radius: 15px;
  border: 1px solid transparent;
}

.events-page-filter-box .selectDiv img {
  position: absolute;
  right: 0;
  top: 12px;
  pointer-events: none;
  bottom: 0;
  width: 17px;
  right: 12px;
  background-color: white;
}

.Listyourevent {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.form-select:focus {
  border-color: transparent !important;
}

.event-view-body {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.home-event-in-box {
  margin-left: 6rem;
  margin-right: 6rem;
}

.home-event-in-box .slick-next:before,
.home-event-in-box .slick-prev:before {
  font-size: 26px;
  line-height: 1;
  opacity: 1 !important;
  color: #0047ab;
}

.home-event-in-box .slick-prev {
  margin-left: -11px !important;
}

.event-page-menu-div {
  margin: 41px 130px 0px 0px;
}

.event-vew-page-margin {
  margin-right: 50px;
}

input.price-range-picker-input {
  width: 45px;
}

html,
body {
  height: 100%;
  margin: 0;
}

.content-area {
  min-height: 100vh;
}


.cat-home-box {
  padding: 7px;
}

.right-aww-sl {
  display: inline;
  position: absolute;
  top: 48%;
  right: -43px;
  height: auto;
  width: 35px;
  cursor: pointer;
}

.left-aww-sl {
  display: inline;
  position: absolute;
  top: 40%;
  left: -48px;
  height: auto;
  width: 35px;
  transform: rotate(-180deg);
  cursor: pointer;
}

.partnetSlider .left-aww-sl,
.partnetSlider .right-aww-sl {
  top: 0 !important;
  width: 30px !important;
}

.home-fil-mar {
  margin-left: 30px;
}

.row.cart-new-div .aaa {
  margin-left: 5px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 100;
  color: #424242;
}

.row.cart-new-div .bbb {
  font-size: 12px;
  color: #424242;
  margin-left: 5px;
  margin-top: 5px;
}

.row.cart-new-div {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 31px;
  top: 2px;
}

.row.cart-new-div .tc {
  font-size: 11px;
}

.extra-phone-css .form-control {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px !important;
}

.extra-phone-css .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.extra-phone-css .react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
}

.coundown-box {
  /* background: #EBF0F8; */
  border-radius: 5px;
  padding: 9px 0px;
}

.cowndown-lable {
  font-size: 12px;
  color: #9B9B9B;
}

.cowndown-val {
  font-size: 18px;
  color: #58585B;
  line-height: 19px;
}

.coundown-number-box {
  margin: 0px 7px;
  height: 43px;
  width: 48px;
}

img.coundown-clock {
  height: 41px;
  width: 36px;
  position: relative;
  top: -3px;
  margin-right: 5px;
}

.days,
.hours,
.minutes {
  position: relative;
}

.days::before,
.hours::before,
.minutes::before {
  content: ':';
  position: absolute;
  right: -11px;
  top: -7px;
  font-size: 23px;
  color: #0047AB;
  line-height: 28px;
}

.Selling-Fast-box {
  background: #0047AB;
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 0;
  right: 15px;
  padding: 1px 12px;
  border-radius: 0px 0px 8px 8px;
}

p.time-name-1 {
  font-size: 13px;
}

p.month-name-1 {
  font-size: 18px;
  color: #0248ab;
  line-height: 29px;
}

p.week-name-1 {
  font-size: 13px;
  line-height: 11px;
}

.day-box-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #0248ab;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  margin-right: 10px;
  color: #0248ab;
  font-size: 22px;
}

.new-week-ticket-box-active:hover .new-week-ticket-box-active {
  background: linear-gradient(#fff, white) padding-box, linear-gradient(to bottom, #0047AB, #fff) border-box !important;
}

.new-week-ticket-box-active {
  background: linear-gradient(#cee3ff, white) padding-box, linear-gradient(to bottom, #0047AB, #fff) border-box !important;
}

.new-week-ticket-box {
  cursor: pointer;
  background: linear-gradient(#fff, white) padding-box, linear-gradient(to bottom, #86b9ff, #fff) border-box;
  border-radius: 6px !important;
  border: 2px solid transparent !important;
  padding: 10px 2px 4px 2px;
}

.new-week-ticket-box p {
  margin-bottom: 0px;
}

.navbar-nav-pc-mergin {
  margin-right: 30px;
}

.pc-top-menu-box {
  border: 1px solid #003b8f !important;
  border-radius: 6px !important;
  padding: 5px 9px !important;
}

.banner-h-event-page {
  padding-top: 15px;
  padding-bottom: 0px;
}

.sh-icon {
  font-size: 23px;
}

.home-category-slide-name {
  font-size: 12px;
}

.filter-show-cat-btn {
  border: none;
  background: none;
  color: #003b8f;
  text-decoration: underline;
  font-size: 14px;
}

.sticky-column {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 0;
  /* Adjust this value based on your header/nav height */
  height: fit-content;
  /* This ensures the column fits its content and does not stretch */
}

.scrollable-column {
  overflow-y: auto;
  height: 1000px;
  /* Adjust the height based on your needs */
  scrollbar-width: none;
  /* For Firefox */
}

.scrollable-column::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and other WebKit browsers */
}

.coupon-ticket-box {
  margin: 5px;
}

.coupon-ticket-box .row {
  border-radius: 13px;
  overflow: hidden;
}

.box-1 {
  background: #0047ab;
  color: #fff;
  padding: 30px 0px;
  position: relative;
}

.box-1::after {
  content: " ";
  background: #fff;
  height: 19px;
  width: 27px;
  position: absolute;
  right: -14px;
  top: -10px;
  border-radius: 50%;
}

.box-1::before {
  content: " ";
  background: #fff;
  height: 19px;
  width: 27px;
  position: absolute;
  right: -14px;
  bottom: -10px;
  border-radius: 50%;
}

.box-2 {
  background: #6495ED;
  color: #fff;
}

.box-1 .title-1 {
  font-size: 24px;
  margin-bottom: 0px;
  line-height: 27px;
}

.box-2 .title-3 {
  font-size: 12px;
  line-height: 27px;
  margin-bottom: 9px;
}

.box-1 .title-2 {
  margin: 0;
  font-size: 11px;
}

.box-2 .title-4 {
  cursor: pointer;
  margin: 0px;
  border: 1px solid #fff;
  border-radius: 7px;
  padding: 3px 0px;
  font-size: 13px;
}

.box-2 .title-5 {
  cursor: pointer;
  margin: 0px;
  border: 1px solid #fff;
  border-radius: 7px;
  padding: 3px 0px;
}

.box-2 .title-6 {
  font-size: 10px;
  margin-bottom: 6px;
  border: 1px solid #fff;
  border-radius: 16px;
  padding: 0px 14px;
  background: #fff;
  color: #000000;
}

span.selling-f-box {
  background: #0248ab;
  color: #fff !important;
  font-size: 14px !important;
  padding: 4px 10px;
  border-radius: 6px;
}

.row.border-bottom-1 span {
  font-size: 22px;
  color: #0248ab;
}

.border-bottom-1 {
  border-bottom: 1px solid #0248ab;
  padding: 0px 0px 13px 0px;
}

.grediant-border-in-ticket {
  background: linear-gradient(#fff, white) padding-box, linear-gradient(to bottom, #0047AB, #fff) border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 6px 15px;
}

.event-page-category-filter-box {
  border: 1px solid #eee;
  border-radius: 7px;
  position: relative;
  padding: 5px 0px;
  cursor: pointer;
}

.event-page-category-filter-box img {
  position: absolute;
  right: 7px;
  top: 9px;
  height: 20px;
  width: 20px;
}

.event-page-category-filter-box p {
  margin-bottom: 0;
  color: #b1c8f6;
  padding-left: 14px;
}

.category-box-new {
  position: relative;
  border: 1px solid #eee;
  top: -3px;
  background: #fff;
  padding: 3px 10px;
  height: 200px;
  overflow-y: auto;
}

.category-box-new-for-dashboard {
  border: 1px solid #eee;
  background: #fff;
  padding: 3px 10px;
  height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  top: 40px;
}

.dash-select-box .css-13cymwt-control {
  border-color: hsl(0deg 0% 93.33%) !important;
}

.dash-select-box .css-1jqq78o-placeholder {
  color: hsl(219.47deg 79.17% 81.18%) !important;
}

.header .header-content {
  height: 73% !important;
}

.dashqrbig {
  height: auto;
  width: 130px;
}

.footer-logo-box1 {
  position: relative;
  left: -15px;
}

.event-page-mergin-currect {
  margin-left: 26px;
  margin-right: 5px;
}

.dash-list-banner-1 {
  height: 208px;
  width: 300px;
}

.faq-page-space {
  height: 600px;
}

.scroll-top-box {
  height: 40px;
  width: 40px;
  background: #0047ab;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 90px;
  right: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.scroll-top-box span {
  line-height: 0px;
}

.select-arrow-custome {
  position: absolute !important;
  right: 7px !important;
  top: 9px !important;
  height: 20px !important;
  width: 20px !important;
}

.event-page-banner-1 img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.events-date-time-box {
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 10px 10px;
  position: relative;
}

.action-btn-box1 {
  position: absolute;
  right: 10px;
  top: 5px;
}

.faq-content-area .accordion-button:not(.collapsed) {
  background-color: #ffffff !important;
  box-shadow: none !important;
}

span.sidebar-icon {
  font-size: 24px;
  line-height: 0px;
  margin-right: 10px;
  color: #555 !important;
}

.ticket-type-dsc p {
  margin: 0px;
  font-size: 15px;
}

.ticket-type-dsc {
  padding-top: 8px;
}

.faq-acc-item {
  border: none !important;
}

.faq-acc-item .accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.event-page-category-filter-box1 span::before {
  content: 'X';
  position: absolute;
  right: 3px;
  top: 8px;
  background: #fff;
  color: #0047ab;
  height: 14px;
  width: 14px;
  text-align: center;
  line-height: 0.9rem;
  border-radius: 50%;
  cursor: pointer;
  font-size: 11px;
}

.event-page-category-filter-box1 span {
  background: #0047ab;
  color: #fff;
  margin: 3px 5px;
  padding: 5px 23px 5px 4px;
  font-size: 13px;
  border-radius: 6px;
  position: relative;
  display: inline-block;
}

.event-page-category-filter-box1 {
  display: inline-block;
  width: 100%;
}

.theme-border .css-13cymwt-control {
  border-color: hsl(0deg 0% 93.33%) !important;
}

img.organiger-logo {
  height: auto;
  width: 60px;
}

.dash-box-icon {
  font-size: 47px;
  color: #222fb9;
}

.dropdown-category .dropdown-toggle::after {
  display: none !important;
}

.dropdown-category {
  border: 1px solid #eee;
  border-radius: 5px;
}

.dropdown-category .event-page-category-filter-box1 {
  display: grid;
}

.newdatefilter span.input-group-text img {
  height: auto;
  width: 21px;
}

.newdatefilter .form-control {
  border-color: rgb(255 255 255 / 0%) !important;
}

.newdatefilter span.input-group-text {
  border: 1px solid rgb(185 184 184 / 0%) !important;
}

.ticket-sts-box p {
  font-size: 11px;
}

.ticket-sts-box h2 {
  font-size: 25px;
}

.editbtn-mng-tic img {
  height: auto;
  width: 23px !important;
}

.metismenu li .ai-icon {
  display: inline-flex !important;
  align-items: center !important;
}

.active-date-filter {
  color: #000 !important;
}

.dash-icon {
  color: #0a4eaf;
  font-size: 35px;
}

.border-theme {
  border: 1px solid #0047ab;
  border-radius: 4px;
  padding: 15px 0px;
}

.theme-text {
  color: #0047ab;
}

span.cut_ticket_price {
  font-size: 12px;
  text-decoration: line-through;
  margin: 0px 5px;
  color: #0047ab;
  text-decoration-thickness: 2px !important;
  /* Adjust the thickness as needed */
}

.event_page_price_title {
  font-size: 12px;
  height: 30px;
}

/* CUSTOM CSS */
.seatmap_seat {
  width: 15px;
  height: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 2px;
}

.seatmap_box {
  padding: 4px;
  border: 1px solid transparent;
  transform-origin: center center;
  display: inline-block;
  border-radius: 4px;
}

.seatmap_box_shadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.192);
}

.seatmap-selected-bg {
  background-color: #000 !important;
}

.StepstImg {
  height: auto;
  width: 70px;
}

.seatmap-info p {
  font-size: 15px;
  margin: 0px 10px;
}

.setmap_indigator_box {
  display: flex;
  align-items: center;
  background: #eee;
  border-radius: 8px;
  padding: 0px 13px;
}
.seat-indigator {
  content: ' ';
  height: 20px;
  width: 20px;
  background: #000;
  border-radius: 28px;
}
.seatmap-price{
  margin: 0px 6px;
}
.price-indigat-cuts {
  text-decoration: line-through;
}
.admin_pending_txt{
  font-size: 14px;
}
.sellingf-text {
  background: #003b8f;
  color: #fff !important;
  padding: 1px 4px;
  border-radius: 3px;
  position: absolute;
  margin-left: 2px;
}