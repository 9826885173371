.places-autocomplete-container {
    position: relative;
}

.suggestions-container {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 5px;
    padding: 10px;
    cursor: pointer;
    z-index: 999;
}

.location-sugg {
    padding: 8px;
    margin-bottom: 5px;
    transition: background-color 0.3s ease;
}

.location-sugg:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}
