/* For screens wider than 2560px */
@media screen and (min-width: 2560px) {}

/* For screens wider than 1920px */
@media screen and (min-width: 1920px) {
    .event-card-img {
        height: 200px;
    }

    .count-sec p {
        font-size: 46px !important;
    }

    .count-sec h6 {
        font-size: 30px !important;
    }

    .partnetSlider img {
        height: auto;
        width: 143px;
    }

    .OURPARTNER-padding {
        font-size: 36px;
    }

    .newsletter-sec {
        width: 50vw;
    }

    .event-hero {
        max-height: 550px;
    }

    .css-1tue3c7 {
        max-width: 1199px;
    }

    .event-hero img {
        max-width: 1200px;
    }

    .thumb {
        width: 230px !important;
    }

    .slider {
        width: 230px !important;
    }

    input.price-range-picker-input {
        width: 60px;
        padding-left: 3px;
    }

    .coundown-number-box {
        margin: 0px 14px;
    }
}

@media only screen and (max-width: 900px) {
    .user_type_btn {
        padding: 9px 17px 3px 17px;
        font-size: 14px;
    }
   
    .header-right .header-profile img {
        width: 25px;
        height: 25px;
    }

    span.user_name {
        font-size: 11px;
    }

    .whiteblueicon_box {
        padding: 2px 0px;
    }

    .dash-header-btns .whiteblueicontitle {
        font-size: 14px;
        margin-right: 0;
    }

    .dash-header-btns .whitestar-icon {
        display: none;
    }

    .header-right .nav-item {
        height: 85%;
    }

    .list-data {
        padding: 20px 40px;
    }

    .event_list_box_main .location-name {
        margin-left: 13px;
        padding-top: 25px;
        margin-bottom: 20px;
    }

    .event_list_box_main .organizer-name-sec {
        display: contents;
    }

    .event_list_box_main .list-ticket-count {
        text-align: right;
        font-size: 14px;
    }

    .event_list_box_main .mob-style {
        display: grid;
        justify-content: center;
    }

    .list-Ticket-amount {
        font-size: 13px;
    }

    .list-Total-Ticket {
        font-size: 11px;
    }
}

@media (min-width: 992px) and (max-width: 1150px) {
    .font-nav {
        font-size: 9px !important;
    }
    .event_page_price_title{
        font-size: 10px;
    }
    span.cut_ticket_price {
        font-size: 8px !important;
        text-decoration-thickness: 1px !important;
    }
    img.organiger-logo {
        height: auto;
        width: 40px;
    }
    .button.Unfollow-btn-1 {
        padding: 3px 5px !important;
        font-size: 9px !important;
    }
    .home_logo {
        width: 135px;
    }
    .row.evnt-page-save-shear-box {
        top: -25px;
    }
    .dash-list-banner-1 {
        height: 176px;
        width: 239px;
    }
    .title-in-cart-view{
        font-size: 16px;
        padding-bottom: 10px;
    }
    .price-in-cart-page{
        font-size: 12px;
    }
    .calculation-box h5 {
        font-size: 14px;
    }
    .calculation-box h3 {
        font-size: 16px;
    }
    .event-cart-display-price {
        font-size: 11px;
    }
    .event-cart-price-box .small {
        font-size: 11px;
    }
    .dashqrbig {
        height: auto;
        width: 80px;
    }
    .header .header-content {
        height: 89% !important;
    }
    .row.cart-new-div {
        top: 3px;
    }
    .banner-h-home-pg {
        font-size: 32px;
    }
    .list-event-desc {
        font-size: 11px;
    }
    .list-event-location {
        font-size: 12px;
    }
    .animation-home-banner {
        margin-left: 17px;
        font-size: 26px;
        padding: 0px 10px;
        width: 506px;
        height: 44px;
    }

    .home-category-slide-name {
        font-size: 8px;
    }
    .space-sec {
        padding: 0rem 1rem;
    }
    .event-pg-icon {
        font-size: 22px;
    }
    span.event-pg-icon-img img {
        width: 26px;
    }
}